


















































































































































































































































































































































































































































































































































































.imgBox {
  display: flex;
  border: 1px solid #797979;
  .CertificationBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    border-right: 1px solid #797979;
    .certificationTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 210px;
        height: 230px;
      }
    }
  }
  .CaptureBox {
    flex: 1;
    width: 500px;
    .CaptureTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .CaptureList {
      flex: 1;
      overflow-y: auto;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 100%;
        height: 230px;
      }
      p {
        padding: 5px 0;
      }
    }
  }
}
