.imgBox {
  display: flex;
  border: 1px solid #797979;
}
.imgBox .CertificationBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  border-right: 1px solid #797979;
}
.imgBox .CertificationBox .certificationTitle {
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #797979;
  background: #f2f2f2;
}
.imgBox .CertificationBox .certifictionImg {
  padding: 20px;
}
.imgBox .CertificationBox .certifictionImg .el-image {
  width: 210px;
  height: 230px;
}
.imgBox .CaptureBox {
  flex: 1;
  width: 500px;
}
.imgBox .CaptureBox .CaptureTitle {
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #797979;
  background: #f2f2f2;
}
.imgBox .CaptureBox .CaptureList {
  flex: 1;
  overflow-y: auto;
}
.imgBox .CaptureBox .certifictionImg {
  padding: 20px;
}
.imgBox .CaptureBox .certifictionImg .el-image {
  width: 100%;
  height: 230px;
}
.imgBox .CaptureBox .certifictionImg p {
  padding: 5px 0;
}
